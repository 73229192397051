<template>
  <div :class="`${prefixCls}-culture`" id="aochenCulture">
    <Separate title="企业文化" desc="COMPONY CULTURE" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-culture-body`">
      <div :class="`${prefixCls}-culture-body-item`" v-for="(item, index) in cultureList" :key="item.id">
        <div class="icon-layout">
          <img :src="icon[index]" alt="">
<!--          <em class="icon iconfont" :class="item.icon" />-->
        </div>
        <div class="desc-layout">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import { siteCorporateCultureList } from '@/api'
export default {
  name: 'AochenCulture',
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      cultureList: [],
      icon: [
        require('@/assets/image/qysm.png'),
        require('@/assets/image/qyjzg.png'),
        require('@/assets/image/qyjyln.png'),
        require('@/assets/image/qyzzhyq.png'),
        require('@/assets/image/qygzmb.png')
      ]
    }
  },
  mixins: [WowMixin],
  components: {
    Separate
  },
  created () {
    this.siteCorporateCultureList()
  },
  methods: {
    siteCorporateCultureList () {
      siteCorporateCultureList().then(({ data }) => {
        this.cultureList = data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -company-culture;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    align-items: center;
    width: 800px;
    flex-grow: 1;
    &-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &-item{
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
        .icon-layout {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55px;
          height: 55px;
          border: 2px solid #003185;
          border-radius: 50%;
          margin-right: 15px;
          flex-shrink: 0;
          .icon {
            font-size: 32px;
          }
        }
        .desc-layout {
          .title {
            text-align: left;
            color: #003185;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 18px;
          }
          .content {
              font-family: arial !important;
              font-size: 16px !important;
              color: #191818 !important;
              line-height: 40px !important;
              font-weight: lighter !important;
          }
        }
      }
    }
  }
</style>
